<template>
  <b-overlay :show="show" rounded="sm">
  <div>
    <CRow>
      <CCol lg="12">
        <WidgetStats />
        <!-- <UserProfileCard  /> -->
        <CCard>
          <CCardHeader>
            <CIcon name="cil-book"/> Latest Content
            <div class="card-header-actions">
                <!-- <small class="text-muted">
                    <CButton color="primary" @click="add_contents">Add + </CButton>
                </small> -->
            </div>
          </CCardHeader>
          <CCardBody>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cil-justify-center"/>
                      <strong> Tutorial - 2 </strong>
                      <div class="card-header-actions">
                      <a 
                          href="https://coreui.io/vue/docs/components/jumbotron" 
                          class="card-header-action" 
                          rel="noreferrer noopener" 
                          target="_blank"
                      >
                          <small class="text-muted">PDF</small>
                      </a>
                      </div>
                  </CCardHeader>
                  <CCardBody>
                      <CJumbotron>
                      <h4>Maths - Chapter - 2</h4>
                      <p class="lead">Language - English</p>
                      <p>Class - 5</p>
                      <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                      <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton>
                      </CJumbotron>
                  </CCardBody>
              </CCard>
            </CCardBody>
        </CCard>
      </CCol>
      <!-- <CCol lg="4">
        <CloudStorageChart />
        <QuickNoteForm />
      </CCol> -->
    </CRow>
  </div>
  </b-overlay>
</template>

<script>
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";


export default {
  name: "Dashboard",
  data(){
    return{
      user:""
    }
  },
  components: {
    WidgetStats,
    UserProfileCard,
    CloudStorageChart,
    QuickNoteForm,
  },
  methods:{
    
  },
  created(){
    let user_id = this.$session.get('user_id');
    this.user = localStorage.getItem('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    }
    if(user_role_id == '8290f938-b20a-42e3-c201-08d8f3241655')
    {
      return '/';
    } 
   
          
  }
};
</script>
